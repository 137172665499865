import _instanceof from "@swc/helpers/src/_instanceof.mjs";
import regeneratorRuntime from "/home/vsts/work/1/s/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import Cookies from "js-cookie";
import { ArticlesEndpoints, baseUrl } from "../utils/endpoints";
var __awaiter = this && this.__awaiter || function(thisArg, _arguments, P, generator) {
    var adopt = function adopt(value) {
        return _instanceof(value, P) ? value : new P(function(resolve) {
            resolve(value);
        });
    };
    return new (P || (P = Promise))(function(resolve, reject) {
        var fulfilled = function fulfilled(value) {
            try {
                step(generator.next(value));
            } catch (e) {
                reject(e);
            }
        };
        var rejected = function rejected(value) {
            try {
                step(generator["throw"](value));
            } catch (e) {
                reject(e);
            }
        };
        var step = function step(result) {
            result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
        };
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var API_URL = process.env.NEXT_PUBLIC_BIOCENTURY_URL;
export var decryptArticle = function(data) {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var SESSa, SESSr, response, parsedResponse;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    SESSa = Cookies.get("SESSa");
                    SESSr = Cookies.get("SESSr");
                    _ctx.next = 4;
                    return fetch("".concat(API_URL, "/api/article"), {
                        method: "POST",
                        headers: Object.assign(Object.assign({
                            "Content-Type": "application/json"
                        }, SESSa && {
                            Authorization: "Bearer ".concat(SESSa)
                        }), SESSr && {
                            Refresh: SESSr
                        }),
                        body: JSON.stringify({
                            data: data
                        })
                    });
                case 4:
                    response = _ctx.sent;
                    _ctx.next = 7;
                    return response.json();
                case 7:
                    parsedResponse = _ctx.sent;
                    return _ctx.abrupt("return", parsedResponse);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
export var getArticlesEditions = function(params) {
    return __awaiter(void 0, void 0, void 0, regeneratorRuntime.mark(function _callee() {
        var id, url, response, parsedResponse;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    id = params.id;
                    url = "".concat(baseUrl).concat(ArticlesEndpoints.GET_ARTICLES_EDITION, "?id=").concat(id);
                    _ctx.next = 4;
                    return fetch(url, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json"
                        }
                    });
                case 4:
                    response = _ctx.sent;
                    _ctx.next = 7;
                    return response.json();
                case 7:
                    parsedResponse = _ctx.sent;
                    return _ctx.abrupt("return", parsedResponse);
                case 9:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
