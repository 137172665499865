import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import { SizeOptions, ViewType } from "@biocentury/components/types";
import compact from "lodash/compact";
import orderby from "lodash/orderBy";
export var getRelatedArticles = function(pkg) {
    var _a, _b, _c;
    if (!pkg) return [];
    var items = [];
    if (pkg.__typename === "ArticlePackagePart") {
        items = _to_consumable_array((_a = pkg === null || pkg === void 0 ? void 0 : pkg.articlePackage) === null || _a === void 0 ? void 0 : _a.articlePackageParts);
    } else if (pkg.__typename === "ArticlePackageRelatedArticle") {
        items = _to_consumable_array((_b = pkg === null || pkg === void 0 ? void 0 : pkg.articlePackage) === null || _b === void 0 ? void 0 : _b.articlePackageRelatedArticles);
    }
    items = (_c = compact(items)) !== null && _c !== void 0 ? _c : [];
    items = orderby(items, [
        "sequence"
    ], [
        "asc"
    ]);
    return items;
};
export var getArticleBlocksFromEdition = function(params) {
    var articlesFromEdition = params.articlesFromEdition, editionId = params.editionId, articleUrl = params.articleUrl;
    if (!articlesFromEdition || !editionId || !articleUrl) return [];
    return articlesFromEdition.map(function(article) {
        return {
            __typename: "ContentCardComponent",
            viewType: ViewType.list,
            size: SizeOptions.compact,
            cards: [
                Object.assign(Object.assign({}, article), {
                    articleUrl: "".concat(articleUrl, "?editionId=").concat(editionId),
                    url: "".concat(article.url, "?editionId=").concat(editionId)
                }), 
            ]
        };
    });
};
